import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
            <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </div>
    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes] = useCountdown(targetDate);
    return (
        <div className="show-counter">
            <DateTimeDisplay value={"05"} type={'Date'} isDanger={false} />
            <DateTimeDisplay value={"May"} type={'Month'} isDanger={false} />
            <DateTimeDisplay value={"2024"} type={'Year'} isDanger={false} /> 
        </div>
        // <ShowCounter
        //     days={days}
        //     hours={hours}
        //     minutes={minutes}
        //     seconds={seconds}
        // />
    );
};

export default CountdownTimer;
