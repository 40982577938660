import './App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountdownTimer from './CountdownTimer';
import Fade from 'react-reveal/Fade';
const App = () => {
  const THREE_DAYS_IN_MS = 20 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  return (

    <div className='uc-section-wrapper'>
      <Container className='uc-section'>
        <Row className='uc-section_logo-row'>
          <Col  >
            <Fade top delay={100}>
              <img src={"./logo.png"} className="uc-section_logo" alt="logo" />
            </Fade>
          </Col>

        </Row>
        <Fade delay={1000}>
          <Row className='uc-section_row'>
            <Col md={12}> 
              <div className='uc-section_row_header'>
                <h1>We Are Almost Ready for Launch</h1>
                <p>Bring your ideas to life through great expertise and consultations</p>
                <div className='uc-section_row_form-section'>
                  <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                </div> 
                <div className='uc-section_row_header_service'>
                  <span>Web Design & Development</span><span className='uc-section_row_header_service_line'>|</span>
                  <span>App Development</span><span className='uc-section_row_header_service_line'>|</span>
                  <span>Research and Development</span>
                </div>
                <div className='uc-section_row_header_service mt-3'>
                  <span>info@truebluedigitalsolutions.in</span><span className='uc-section_row_header_service_line'>|</span>
                  <span>9363076141</span> 
                </div>
              </div>
            </Col>
          </Row>
        </Fade>

      </Container>
    </div>

  );
}

export default App;
